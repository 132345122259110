/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _        = require('underscore'),
      $        = require('jquery'),
      events   = require('events'),
      Class    = require('class.extend');

   return Class.extend(_.extend({

      init: function(context) {
         this.$el = $('#' + context.htmlElementId);
         this.portletId = context.portletId;
         this.route = context.route;
         this.locale = context.locale;
         this.defaultLocale = context.defaultLocale;
         this.webAppId = context.webAppId;
         this.webAppVersion = context.webAppVersion;
         this.childComponentStateExtractionStrategy = context.childComponentStateExtractionStrategy;
      }
   }, events));
});
