/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author albin
 */
define(function(require) {
   'use strict';

   var
      _        = require('underscore'),
      $        = require('jquery'),
      Class    = require('class.extend'),
      events   = require('events');

   var EVENT_SPLITTER = /^(\S+)\s*(.*)$/;

   var generateUUID = (function() {
      function s4() {
         return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
      }

      return function() {
         return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
      };
   }());

   function iterateDomEvents(events, callback, context) {
      if (!events) {
         return;
      }

      _.each(events, function(handler, event) {
         var match = event.match(EVENT_SPLITTER);

         callback.call(context, match[1], match[2], handler);
      });
   }

   function bindObjectEvents(events, emitter, context) {
      if (!events) {
         return;
      }

      _.each(events, function(callback, event) {
         context.listenTo(emitter, event, context[callback]);
      });
   }

   function unbindObjectEvents(events, emitter, context) {
      if (!events) {
         return;
      }

      _.each(events, function(callback, event) {
         context.stopListening(emitter, event, context[callback]);
      });
   }

   function bindInternalEvents(events, context) {
      if (!events) {
         return;
      }

      _.each(events, function(callback, event) {
         context.on(event, context[callback]);
      });
   }

   function bindStoreSubscription(callback, store, context) {
      if (!callback) {
         return;
      }

      context._unsubscribe = store.subscribe(function() {
         var newState = this.filterState(store.getState(), this.options);

         if (!_.isEqual(this.state, newState)) {
            this[callback](newState);
         }
      }.bind(context));
   }

   function unbindStoreSubscription(context) {
      context._unsubscribe && context._unsubscribe();
   }

   function unbindInternalEvents(events, context) {
      if (!events) {
         return;
      }

      _.each(events, function(callback, event) {
         context.off(event, context[callback]);
      });
   }

   function unbindEventsRecursively(subComponents) {
      subComponents.forEach(function(subComponent) {
         subComponent._unbindEvents();

         if (subComponent._subComponents && subComponent._subComponents.length) {
            unbindEventsRecursively(subComponent._subComponents);
         }
      });
   }

   var triggerMethod = (function() {
      // split the event name on the ":"
      var splitter = /(^|:)(\w)/gi;

      // take the event section ("section1:section2:section3")
      // and turn it in to uppercase name
      function getEventName(match, prefix, eventName) {
         return eventName.toUpperCase();
      }

      return function(context, event, args) {
         var noEventArg = arguments.length < 3;

         if (noEventArg) {
            args = event;
            event = args[0];
         }

         // get the method name from the event name
         var methodName = 'on' + event.replace(splitter, getEventName);
         var method = context[methodName];
         var result;

         // call the onMethodName if it exists
         if (_.isFunction(method)) {
            // pass all args, except the event name
            result = method.apply(context, noEventArg ? _.rest(args) : args);
         }

         // trigger the event, if a trigger method exists
         if (_.isFunction(context.trigger)) {
            if (noEventArg + args.length > 1) {
               context.trigger.apply(context, noEventArg ? args : [event].concat(_.drop(args, 0)));
            } else {
               context.trigger(event);
            }
         }

         return result;
      };
   }());

   function create(context, require, app, router, i18n, globalEvents, store) {
      return Class.extend(_.extend({

         tagName: 'div',

         isRendered: false,

         init: function(state, options) {
            var serverRendered;

            if (options) {
               this.$el = options.$el;
               this.cid = options.cid;
               serverRendered = options.serverRendered;
               this.options = options.options;
            } else {
               options = {};
            }

            if (!this.cid) {
               this.cid = generateUUID();
            }

            this._bindEvents();
            this.state = state;
            this._subComponents = [];

            this.listenToOnce(app, 'components:loaded', function(components) {
               var subComponents = options.subComponents;

               if (!subComponents) {
                  return;
               }

               this._subComponents = subComponents.map(function(componentId) {
                  return components[componentId];
               });
            });

            this.on('attached', this._bindDOMEvents);
            this.triggerMethod('init');

            if (serverRendered) {
               var eventOptions = { serverRendered: serverRendered };

               this.triggerMethod('rendered', eventOptions);
               this.triggerMethod('attached', eventOptions);
            }
         },

         // Overridden by Components to filter a state from the WebApp's global state (store)
         filterState: function() {
            return {};
         },

         $: function(selector) {
            return this.$el.find(selector);
         },

         _bindEvents: function() {
            if (!this.events) {
               return;
            }

            bindObjectEvents(this.events.app, app, this);
            bindObjectEvents(this.events.router, router, this);
            bindObjectEvents(this.events.global, globalEvents, this);
            bindStoreSubscription(this.events.store, store, this);
            bindInternalEvents(this.events.self, this);
         },

         _bindDOMEvents: function() {
            if (!this.events) {
               return;
            }

            iterateDomEvents(this.events.dom, function(event, selector, handler) {
               this.$el
                  .off(event, selector, this[handler])
                  .on(event, selector, $.proxy(this[handler], this));
            }, this);
         },

         _unbindEvents: function() {
            if (!this.events) {
               return;
            }

            unbindObjectEvents(this.events.app, app, this);
            unbindObjectEvents(this.events.router, router, this);
            unbindInternalEvents(this.events.self, this);
            unbindStoreSubscription(this);
            iterateDomEvents(this.events.dom, function(event, selector, handler) {
               this.$el.off(event, selector, this[handler]);
            }, this);
         },

         _templateFunctions: function() {
            var renderTemplate = _.bind(this.renderTemplate, this),
               _this = this;

            return {
               renderer: {
                  renderPartial: function(path, options) {
                     var template = require(path);

                     return renderTemplate(template, options);
                  },
                  renderComponent: function(componentName, options) {
                     var Component = require('/component/' + componentName),
                        state = Component.prototype.filterState(store.getState(), options),
                        component = new Component(state, {options: options});

                     _this._subComponents.push(component);
                     _this.renderedSubComponents[component.cid] = component;

                     var tagName = _.escape(_.result(component, 'tagName'));

                     return '<' + tagName + ' data-cid="' + component.cid + '"></' + tagName + '>';
                  }.bind(this)
               },
               getUrl: _.bind(router.getUrl, router),
               getStandaloneUrl: _.bind(router.getStandaloneUrl, router),
               getResourceUrl: function(path) {
                  if (path.charAt(0) === '/') {
                     path = path.substring(1);
                  }

                  return '/webapp-files/' + app.webAppId + '/' + app.webAppVersion + '/' + path;
               },
               i18n: i18n,
               appContext: {
                  getWebAppNamespace: function(prefix) {
                     return prefix + context.portletId.replace('.', '_');
                  }
               }
            };
         },

         _ensureEl: function() {
            if (!this.$el) {
               this.$el = $('<' + _.escape(_.result(this, 'tagName')) + '/>').data('cid', this.cid);
            }
         },

         getTemplate: function() {
            return this.template;
         },

         renderTemplate: function(template, options) {
            return template(
               _.extend(
                  this._templateFunctions(),
                  _.result(this, 'templateFunctions'),
                  options
               )
            );
         },

         render: function() {
            var attributes,
               className;

            this.renderedSubComponents = {};
            unbindEventsRecursively(this._subComponents);
            this._subComponents = [];
            this._ensureEl();

            className = _.escape(_.result(this, 'className'));
            if (className) {
               this.$el.addClass(className);
            }

            attributes = _.result(this, 'attributes');
            if (attributes) {
               this.$el.attr(attributes);
            }

            this.$el.html(this.renderTemplate(this.getTemplate(), this.state));
            this.isRendered = true;

            this.triggerMethod('rendered');

            _.each(this.renderedSubComponents, function(component, cid) {
               this.$el
                  .find('[data-cid="' + cid + '"]')
                  .replaceWith(component.render().$el);
            }, this);

            this.triggerMethod('attached');
            return this;
         },

         destroy: function() {
            this.triggerMethod('destroy');
            this._unbindEvents();
            unbindEventsRecursively(this._subComponents);

            this.$el && this.$el.remove();
         },

         setState: function(key, value, options) {
            var attrs,
               changed = {};

            if (typeof key === 'object') {
               attrs = key;
               options = value;
            } else {
               (attrs = {})[key] = value;
            }

            options || (options = {});

            _.each(attrs, function(value, key) {
               var oldValue = this.state[key];

               if (oldValue !== value) {
                  this.state[key] = value;
                  changed[key] = value;
               }
            }, this);

            _.each(changed, function(value, key) {
               this.triggerMethod('state:changed:' + key, value, options);
            }, this);

            this.triggerMethod('state:changed', changed, options);
         },

         triggerMethod: function() {
            triggerMethod(this, arguments);
         }
      }, events));
   }

   return {
      create: create
   };
});
